<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Match the letters with the parts of a titration curve.</p>

      <img
        src="/img/assignments/ChemUCI1LC_Q4S1_q4_img.png"
        alt="Image of a titration set up"
        style="max-width: 413px"
        class="mb-5"
      />

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 300px"
          class="my-1 ml-3"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LC_Q4S1_Q4',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      options: [
        {text: 'Analyte pH', value: 'analyte'},
        {text: 'Buffering region', value: 'buffering'},
        {text: 'Equivalence point', value: 'equivalence'},
        {text: 'Not of importance', value: 'notImportant'},
        {text: 'Titrant pH', value: 'titrant'},
      ],
      letterOptions: [
        {text: 'A: ', inputName: 'input1'},
        {text: 'B: ', inputName: 'input2'},
        {text: 'C: ', inputName: 'input3'},
        {text: 'D: ', inputName: 'input4'},
        {text: 'E: ', inputName: 'input5'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
